<template>
	<div class="opacity-page show">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('general.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<button class="btn-tbf blue" @click="$router.push({name: 'process-create'})" v-if="$auth.user().rights.admins_crud">
							<div class="icon">
								<icon-plus />
							</div>
							<span class="text" v-if="$resize && $mq.above(600)">{{ $t('processes.new_process') }}</span>
						</button>

						<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
							<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
							<span class="text">{{ $t('general.filters') }}</span>
							<div class="icon-filter">
								<icon-arrow />
							</div>
						</button>
						<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
							<div class="icon"><icon-settings /></div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.below(500)}]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedProcedures.length">{{ $t('filters.by_procedures') }}</span>
							<span class="text" v-else-if="selectedProcedures.length == 1">{{ filterProcedures.find(el => el.id == selectedProcedures[0]).name }}</span>
							<span class="text" v-else>{{ selectedProcedures.length }} {{ $t('filters.procedures_selected') }}</span>

							<div class="icon-filter" v-if="!selectedProcedures.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedProcedures')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchProcedures" :placeholder="$t('filters.search_procedures')">
							</div>

							<template v-for="procedure in filteredProcedures">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedProcedures', procedure.id)" v-bind:class="{active: selectedProcedures.includes(procedure.id)}" :key="'filter-procedure-' + procedure.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ procedure.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
							<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find(el => el.id == selectedUsers[0]).name }}</span>
							<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.responsibles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')">
							</div>

							<template v-for="user in filteredUsers">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-user-' + user.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ user.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div v-if="$auth.user().rights_name.includes('master_admin', 'admin')" class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedStatus.length">{{ $t('filters.by_status') }}</span>
							<span class="text" v-else-if="selectedStatus.length == 1">{{ filterStatus.find(el => el == selectedStatus[0])}}</span>
							<span class="text" v-else>{{ selectedStatus.length }} {{ $t('filters.status_selected') }}</span>

							<div class="icon-filter" v-if="!selectedStatus.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedStatus')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchStatus" :placeholder="$t('filters.search_status')">
							</div>
							
							<template v-for="status in filteredStatus">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedStatus', status)" v-bind:class="{active: selectedStatus.includes(status)}" :key="'filter-status-' + status">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ status }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<!-- <div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedCategories.length">{{ $t('filters.by_categories') }}</span>
							<span class="text" v-else-if="selectedCategories.length == 1">{{ filterUsers.find(el => el.id == selectedCategories[0]).name }}</span>
							<span class="text" v-else>{{ selectedCategories.length }} {{ $t('filters.categories_selected') }}</span>

							<div class="icon-filter" v-if="!selectedCategories.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedCategories')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchCategories" :placeholder="$t('filters.search_categories')">
							</div>

							<template v-for="category in filteredCategories">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedCategories', category.id)" v-bind:class="{active: selectedCategories.includes(category.id)}" :key="'filter-user-' + category.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ category.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div> -->
					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(501)"/>
						<div v-else-if="$resize && $mq.below(500)">{{$t('filters.clear_all')}}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else/>

		<template v-if="loadedList">
			<div class="data-list list-goals" v-if="processes.length">
				<div class="row-space-tbf header-list" v-bind:class="{'border-t-0': extendFilters}">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-index">
							<div class="text">
								{{ $t('processes.index') }}
							</div>
						</div>
						<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy === 'name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('processes.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-versions">
							<div class="text">{{ $t('processes.versions') }}</div>
						</div>
						<div v-if="$resize && $mq.above(650)" class="column-filter column-version-name">
							<div class="text">{{ $t('processes.active_version') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-procedures-count">
							<div class="text">{{ $t('processes.procedures') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-update_requests">
							<div class="text">{{ $t('processes.update_requests') }}</div>
						</div>
						<div class="column-filter column-user" v-if="$resize && $mq.above(945)"></div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="(processItem, index) in processes">
					<div class="row-space-tbf row-list-item" :key="processItem.id">
						<div class="space-left"><icon-chart v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="column-simple-text column-index">
								<div class="text">
									{{ index + 1 }}
								</div>
							</div>
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<router-link class="column-name-title column-name" :to="{name: 'process-show', params: {slug: processItem.slug}}">
								<div class="circle-status" :class="{active: processItem.status == 'live'}"></div>
								<div class="name">{{ processItem.name }}</div>
							</router-link>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-versions">
								<div class="text">{{ processItem.total_versions }}</div>
							</div>
							<div v-if="$resize && $mq.above(650)" class="column-simple-text column-version-name">
								<div class="text">{{ processItem.publish_version ? processItem.publish_version.name : ''}}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-procedures-count">
								<div class="text">{{ processItem.publish_version ? processItem.publish_version.procedures_count : '' }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-update_requests">
								<div class="text">{{ processItem.improvement_proposals.active }}</div>
							</div>
							<div class="column-avatar column-user" v-if="$resize && $mq.above(945)">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="processItem.responsible">
									<img :src="processItem.responsible.avatar" v-if="processItem.responsible && processItem.responsible.avatar">
								<div class="user-circle" v-else>
									<icon-user />
								</div>
									<template slot="popover">
										<div class="simple-text">{{processItem.responsible.first_name + ' ' + processItem.responsible.last_name}}</div>
									</template>
								</v-popover>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+processItem.id" :id="'dropdownEdit'+processItem.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ processItem.id " :aria-labelledby="'dropdownEdit'+processItem.id">
										<div class="dropdown-item" @click="viewProcess(processItem.slug)"><div class="simple-text">{{$t('general.view')}}</div></div>
										<div class="dropdown-item" @click="editProcess(processItem.slug)" v-if="processItem.rights.edit"><div class="simple-text">{{ $t('general.edit') }}</div></div>
										<div class="dropdown-item" @click="showModal('clone', {slug: processItem.slug, type: 'process', from: 'processes'})" v-if="processItem.rights.clone"><div class="simple-text">{{ $t('general.clone') }}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'process', from: 'index_processes', model: processItem})" v-if="processItem.rights.delete"><div class="simple-text">{{ $t('general.delete') }}</div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+processItem.id" :id="'dropdownEdit'+processItem.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ processItem.id " :aria-labelledby="'dropdownEdit'+processItem.id">
									<div class="dropdown-item" @click="viewProcess(processItem.slug)"><div class="simple-text">{{$t('general.view')}}</div></div>
									<div class="dropdown-item" @click="editProcess(processItem.slug)" v-if="processItem.rights.edit"><div class="simple-text">{{ $t('general.edit') }}</div></div>
									<div class="dropdown-item" @click="showModal('clone', {slug: processItem.slug, type: 'process', from: 'processes'})" v-if="processItem.rights.clone"><div class="simple-text">{{ $t('general.clone') }}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'process', from: 'index_processes', model: processItem})" v-if="processItem.rights.delete"><div class="simple-text">{{ $t('general.delete') }}</div></div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t('empty.title')}}</div>
					<div class="description" v-html="$t('empty.processes_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.admins_crud">
						<button class="btn-tbf blue center" @click="$router.push({name: 'process-create'})">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t('processes.new_process')}}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>

		<infinite-loading :identifier="infiniteId" @infinite="infiniteProcessesHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
	import IconChart from '../../Icons/Chart'
    import IconSearch from '../../Icons/Search'
    import IconPlus from '../../Icons/Plus'
    import IconArrow from '../../Icons/Arrow'
    import IconTrash from '../../Icons/Trash'
    import IconClose from '../../Icons/Close'
	import IconUser from '../../Icons/User'
    import IconEdit from '../../Icons/EditDots'
	import IconSettings from '../../Icons/Settings'
	import IconEditMobile from '../../Icons/Edit'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
			IconChart,
            IconSearch,
            IconPlus,
            IconArrow,
            IconTrash,
            IconClose,
			IconUser,
            IconEdit,
			IconSettings,
			IconEditMobile,
            LoaderItemsList,
            LoaderHeaderList,
			InfiniteLoading,
        },
        data() {
            return {
            	loaded: false,
            	loadedList: false,
            	search_filter: '',
            	queryFiltersCount: 0,
            	extendFilters: true,
            	processes: [],
            	sortBy: '',
            	sortDirection: 'asc',
				filterProcedures: [],
				selectedProcedures: [],
				searchProcedures: '',
				selectedUsers: [],
				searchUsers: '',
				selectedStatus: [],
				searchStatus: '',
				page: 0,
				infiniteId: 1,
				selectedCategories: [],
				filterCategories: [],
				searchCategories: '',
            }
        },
        async mounted(){
        	this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)

			await this.getFilters()
        	this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('navbar.processes');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshProcessesIndex', () => {
				this.getFilters()
				this.checkQueryFilters()
				this.searchFilterFunct()
			})
        },
        watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
        computed: {
            filteredProcedures(){
				return getByKeywordFilter(this.filterProcedures, this.searchProcedures)
			},
			filteredUsers(){
				return getByKeywordFilter(this.filterUsers, this.searchUsers)
			},
			filteredStatus(){
				return getByKeywordStatus(this.filterStatus, this.searchStatus)
			},
			filteredCategories(){
				return getByKeywordFilter(this.filterCategories, this.searchCategories)
			}
        },
        methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {procedures: true, users: true , process_status: true}})
				.then(({data}) => {
        			this.filterUsers = data.data.users
        			this.filterProcedures = data.data.procedures
					this.filterStatus = data.data.process_status
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			infiniteProcessesHandler($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

        		if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}
        		
        		axios.get(this.$auth.user().instance.id + '/processes', {params: paramsCall})
        		.then(({data}) => {
        			if(data.data.length){
        				this.page++
        				this.processes.push(...data.data)
        				setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 20){
        					$state.complete()
        				}
        			}else{
        				$state.complete()
        			}

        			this.loadedList = true
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
        	},
			
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
					if(key == 'procedures'){ paramsCall.procedure_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'users'){ paramsCall.user_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'process_status'){ paramsCall.status = dataQuery[key].split(',').map(String) }
				});
			},
        	selectOptionFilter(filter, value) {
        		if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
				} else {
					if(this[filter].includes(parseInt(value))){
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
				}

				this.addFilterToRouteQuery(filter)
        	},
        	addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedProcedures' || filterName == 'all'){ dataQuery['procedures'] = this.selectedProcedures.toString(); }

				if(filterName == 'selectedStatus' || filterName == 'all'){ dataQuery['process_status'] = this.selectedStatus.toString(); }

				if(filterName == 'selectedUsers' || filterName == 'all'){ dataQuery['users'] = this.selectedUsers.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct();
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedProcedures = []
					this.selectedUsers = []
					this.selectedStatus = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);

				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
					if(key == 'procedures'){ this.selectedProcedures = dataQuery[key].split(',').map(Number) }
					if(key == 'users'){ this.selectedUsers = dataQuery[key].split(',').map(Number) }
					if(key == 'process_status'){ this.selectedStatus = dataQuery[key].split(',').map(Number) }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
				this.searchFilterFunct()
			},
			editProcess(processSlug){
				this.$router.push({ name: 'process-edit', params: {slug: processSlug} })
			},
			viewProcess(processSlug){
				this.$router.push({ name: 'process-show', params: {slug: processSlug} })	
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.processes = []
				this.infiniteId += 1
			},
        }
    }

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
	function getByKeywordStatus(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		
		return list.filter(item => item.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}

</script>

<style lang="scss">
	.list-goals{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}

		.column-filter.sortable.column-name {
			min-width: 150px;
		}
		.column-index{
			flex: 0 0 40px 
		}
		.column-name{
			flex: 1 1 auto;
			cursor: pointer;
			display: flex;
			align-items: center;
			outline: none;
			.circle-status{
				height: 10px;
				width: 10px;
				min-width: 10px;
				border-radius: 50%;
				background: #c5c5c5;
				border: 1px solid $secondary;
				margin-right: 7px;
				&.active{
					background: #6CE0A6;
					border: 1px solid #03AE58;
				}
			}
		}
		.column-versions{
			flex: 0 0 120px;
		}
		.column-version-name{
			flex: 0 0 130px;
		}
		.column-procedures-count{
			flex: 0 0 100px;
		}
		.column-update_requests {
			flex: 0 0 120px;
		}
		.column-user{
			flex: 0 0 90px;
			display: flex;
			justify-content: flex-end;
		}
		@media (max-width: 1115px) {
			.column-versions,
			.column-version-name,
			.column-procedures-count,
			.column-update_requests{
				flex: 0 0 100px;
			}
		}
	}
</style>